import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'ngx-user-renderer',
  templateUrl: './user-renderer.component.html',
  styleUrls: ['./user-renderer.component.scss']
})
export class UserRendererComponent implements ICellRendererAngularComp {

  userCount = 0;
  userNames = '';

  constructor() { }

  agInit(params: any): void {
    this.userCount = params.data.userCount;
    this.userNames = params.data.users;
  }

  refresh(): boolean {
    return false;
  }

}
