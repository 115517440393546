import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'ngx-status-renderer',
  templateUrl: './status-renderer.component.html',
  styleUrls: ['./status-renderer.component.scss']
})
export class StatusRendererComponent implements ICellRendererAngularComp {

  statusVal = 0;
  statusText = '';
  boxClass = '';

  texts = ['lobby', 'voting', 'closed', 'closed by user leave', 'closed by match', 'closed by inactivity']
  classes = ['lobby', 'voting', 'closed', 'user-leave', 'match', 'inactivity'];

  constructor() { }

  agInit(params: any): void {
    this.statusVal = params.data.status;
    this.statusText = this.texts[this.statusVal];
    this.boxClass = this.classes[this.statusVal];
  }

  refresh(): boolean {
    return false;
  }

}
