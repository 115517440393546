import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Created with ♥ by <b><a href="https://sstauden.de/" target="_blank">Sven Stauden</a></b>
    </span>
    <div class="socials">
      <!-- <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a> -->
      <a href="https://twitter.com/Match_a_Movie" target="_blank" class="ion ion-social-twitter"></a>
      <!-- <a href="#" target="_blank" class="ion ion-social-linkedin"></a> -->
    </div>
  `,
})
export class FooterComponent {
}
